'use client';

import { SignIn } from '@clerk/nextjs';

export default function Page() {
    // const { user, isSignedIn } = useUser();

    // const handleSignIn = () => {
    //     if (user && isSignedIn) {
    //         return '/tables';
    //     } else {
    //         return '/sales';
    //     }
    // };

    // return <SignIn afterSignInUrl={handleSignIn()} />;

    return <SignIn />;
    // Customization
    // return (
    //   <div className="flex min-h-screen items-center justify-center">
    //     <SignIn
    //       appearance={{
    //         elements: {
    //           footer: {
    //             display: "none",
    //           },
    //         },
    //       }}
    //     />
    //   </div>
    // );
}
